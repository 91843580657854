.gauge-wrapper {
  position: relative;
  text-align: center;
  &.gauge-button-padding {
    padding-bottom: 20%;
  }
  transition: all 1.7s ease-out;
  transition-delay: 0.0s
}
.gauge,
.pointer {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  transition: transform 1.7s ease-out;
}

.pointer-animation-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}

.gauge {
  display: block;
}
.gauge-text {
  margin-top: -50%;
}

.card-title {
  position: relative;
  font-size: 1.2em;
}
