.stripe {
  height: 2rem;
  border-bottom-right-radius: 50px;
}
.stripe {
  background-color: hsl(0, 0%, 59%);
} /* Fallback when nothing is provided */
.stripe.web {
  background: hsl(170, 84%, 78%);
}
.stripe.book {
  background: hsl(108, 84%, 78%);
}
.stripe.video {
  background: hsl(229, 84%, 78%);
}

.timing-icon {
  font-size: 1.25rem;
}

.stripe-icon {
  font-size: 1.5rem;
  padding-left: 1rem;
}
.like-icon {
  font-size: 1.5rem;
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  transform: scale(1);
  transition: all 0.2s;
}
.like-icon:hover {
  opacity: 0.7;
}
.like-icon:focus,
.like-icon:active {
  opacity: 0.4;
}

.resource-card {
  border: 1px solid black !important;
  padding: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 15px;
  margin-right: 15px;
}

.resource-card-title {
  /* padding-left: 0.5em;
  padding-top: 0.5em; */
  padding: 0.5em;
  padding-bottom: 1.5em;
  min-height: 5.5em;

  b {
    @media screen and (min-width: 768px) {
      font-size: 1.5em;
    }
    font-size: 1.2em;
    width: 80%;
    margin-left: 0px;
    margin-right: auto;
    display: block;
  }
}


/* Pulse animation for server replies, used on the star for like */
.server-reply {
  animation: pulse 1s none;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  50% {
    background: rgba(0, 0, 0, 00);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    background: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* Reset overrides from the base css */
#search.btn {
  height: initial;
}

.resource-card .action-button {
  position: absolute;
  bottom: 2rem;
}
