/* lets add_some_bootstrap_overrives_for_attractiveness {} */

/* --------------- [ brand colors ] --------------- */

$background-color: #f5f9f0;
$title-background-color: #dfedcb;
$title-accent-color: #004557;
$heading-color: #005630;
$link-color: #127190;

$nav-accent-color: #065569;
$nav-link-active-color: #105468;
$nav-link-inactive-color: #ffffff;
$nav-text-color: #242424;

$boxitem-title-background-color-blue: #c4e9f7;
$boxitem-title-color-blue: #004557;
$boxitem-title-background-color-green: #dfedcb;
$boxitem-title-color-green: #005630;
$boxitem-title-background-color-gray: #dcdcdb;
$boxitem-title-color-gray: #000000;

$boxitem-content-background-color: #ffffff;

$btn-primary-bg-color: #105468;
$btn-primary-focus-color: #004458;
$btn-outline-primary-color: $nav-accent-color;
$btn-outline-primary-bg-color: #ffffff;
$btn-outline-primary-border-color: #b4b2b2;
$input-border-color: #127190;
$input-group-text-bg-color: #57585a;
$input-group-text-color: #ffffff;

$form-background-color: #222222;
$form-background-active-color: #5489a2;
$form-checked-background-color: #c0ebff;

// for some reason we use the same color here
$survey-accent-color: $heading-color;

/* --------------- [ menu/footers ] --------------- */

.App {
  background-color: $background-color;
}

footer {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 2px $nav-link-active-color solid;
  & div.border-right {
    border-right: $nav-link-active-color 2px solid !important;
  }
  #footer-container {
    text-align: center;
    .footer-logo {
      width: 100%;
      max-height: 70px;
    }
  }
}

.logo {
  max-width: 240px;
}

.title-section {
  background-color: $title-background-color;
}

.title-section h1 {
  margin: 0px;
  color: $title-accent-color;
}

.title-section h2 {
  margin: 0px;
  color: #004557;
  font-weight: normal;
  font-size: 1.5em;
}

.title-container {
  background-position: right;
  background-repeat: no-repeat;
  background-image: url("../images/watermark.png");
  background-size: contain;
  padding-top: 2em;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.navbar {
  border-bottom: 5px solid $nav-accent-color;
  padding-bottom: 0;
}

.nav-item {
  background-color: $nav-link-inactive-color;
}

.nav-tabs .nav-link {
  color: $nav-text-color;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 0.75em;
  padding-top: 0.75em;
  border-bottom: 0px;
}

.nav-tabs .nav-link.active {
  background-color: $nav-link-active-color;
  color: $nav-link-inactive-color;
}

div.navbar-collapse {
  margin-top: auto;
}

/* ------------------ [ layout ] ------------------ */

.boxitem-title {
  border-radius: 35px 0px 0px 0px;
  padding: 20px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 0px;

  &.blue {
    background-color: $boxitem-title-background-color-blue;
    color: $boxitem-title-color-blue;
  }
  &.green {
    background-color: $boxitem-title-background-color-green;
    color: $boxitem-title-color-green;
  }
  &.gray {
    background-color: $boxitem-title-background-color-gray;
    color: $boxitem-title-color-gray;
  }
  & h2,
  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: inherit;
  }
}

.boxitem-content {
  border-radius: 0px 0px 35px 0px;
  background-color: $boxitem-content-background-color;
  padding: 20px 40px 20px 40px;

  @media screen and (min-width: 1200px) {
    &.x-padding {
      padding-left: 15%;
      padding-right: 15%;
    }
  }

  &.top-margin {
    margin-top: 40px;
  }
}

.boxitem-container {
  background-color: $boxitem-content-background-color;
  & .boxitem-content:not(:last-child) {
    padding-bottom: 0px;
  }
}
.no-top {
  border-radius: 35px 0px 35px 0px;
  // padding: 20px 20px 20px 20px;
}

/* ------------------- [ forms ] ------------------ */

p,
li,
.form-group {
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 20px;
}

input[type="text"],
input[type="password"],
input[type="date"],
textarea.form-control,
select.selectQuestion,
input[type="email"] {
  border-radius: 0.25em;
  height: 42px;
  border-color: $input-border-color;
  &:focus  {
    border-color: $input-border-color;
    box-shadow: 0 0 0 0.2rem rgba($input-border-color, 0.25);
  }
}

.input-group {
  border-radius: 0.3rem;
  &:focus-within  {
    border-color: $input-border-color;
    box-shadow: 0 0 0 0.2rem rgba($input-border-color, 0.25);
  }
  input:focus {
    box-shadow: none;
  }
}


.btn {
  font-weight: bold;
  padding: 0.5em 2em;
  text-decoration: none;
}

.btn-primary {
  background-color: $btn-primary-bg-color;
  border: 0px;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:focus-within {
  background-color: $btn-primary-focus-color;
  border: none;
  border: 0px;
}

.btn-outline-primary {
  background-color: $btn-outline-primary-bg-color;
  color: $btn-outline-primary-color;
  border-color: $btn-outline-primary-border-color;
  &:hover,
  &:active,
  &:focus {
    color: $btn-outline-primary-bg-color;
    background-color: $btn-outline-primary-color;
    border-color: $btn-outline-primary-border-color;
  }
}

.form-check label.question {
  background-color: $form-background-color;
  padding: 25px;
  border-radius: 10px;
  display: inline-block;
  margin: 10px;
  max-width: 170px;
  text-align: center;
  position: relative;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.form-check label.question:hover,
.form-check label.question:active {
  cursor: pointer;
  background-color: $form-background-active-color;
  color: white;
}

.form-check input:checked + span {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: $form-checked-background-color;
  display: block;
  z-index: -1;
  border-radius: 10px;
}

.input-group-text {
  color: $input-group-text-color;
  background: $input-group-text-bg-color;
  border: none;
}

/* ---------------- [ typography ] ---------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: bold;
}

h1,
h2 {
  color: $heading-color;
}

a {
  color: $link-color;
  text-decoration: underline;
}

a.btn-block {
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: scale-color($color: $link-color, $lightness: -15%);
}

/* ------------------- [ pages ] ------------------ */

/* ABOUT */

.steps p {
  width: 100%;
  padding-bottom: 30%;
}

#about .container {
  margin-top: 20px;
  padding: 60px;
}

#about h1,
h2,
h3 {
  color: black;
  font-weight: 900;
  width: 100%;
}
.hidden {
  display: none;
}

/*  SURVEY */

.invalid-feedback {
  text-align: center;
  font-size: 1em;
  @media screen and (min-width: 768px) {
    font-size: 1.6em;
  }
}

.questionText {
  font-weight: 500;
  font-size: 1.2em;
  line-height: 1.5;
}

.badge-secondary {
  font-size: 1.2em;
  display: inline-block;
}

#login-form .form-group {
  margin-top: 0px;
  &:nth-child(1) {
    margin-bottom: 0px;
  }
}

#survey-container .survey-section-header {
  h3,
  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: block;
  }
  &.rule {
    border-top: 2px solid $survey-accent-color;
    margin-top: 2em;
  }
}

.store-link {
  width: 200px;
  padding: 0.5em;
  // height: 120px;
}

/* Participant information sheet */

h4.pis-heading {
  margin-bottom: 20px;
  color: $title-accent-color;
}
/* Resource page */

.resource-header-container {
  position: relative;
  margin-bottom: 2em;
}

.resource-header-image {
  width: 100%;
  border: 1px solid black;
}

.resource-header-video-button {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.resource-header-liked {
  position: absolute;
  right: 1em;
  top: 1em;
  .like-icon {
    padding: 0.5em;
  }
}

.resource-block-icon {
  margin-left: -5px;
  padding-right: 0.5em;
}

.resource-card-thumbnail {
  width: 100%;
  height: 150px;
  background-position: center;
  background-size: cover;
}

// ensure video-modal is centered
.video-modal .modal-content {
  width: auto;
}

.video-modal {
  max-width: fit-content;
  line-height: 0px;
}

.btn-square {
  width: 2em;
  height: 2em;
  padding: 0;
  margin-left: 1em;
}

// very specific 
.resource-collapse-header-button-blue:not(:disabled):not(.disabled) {
  background-color: $boxitem-title-color-blue;
  &:hover,
  &:active,
  &:focus {
    background-color: $boxitem-title-color-blue;
  }
}

.resource-collapse-header-button-gray:not(:disabled):not(.disabled) {
  background-color: $boxitem-title-color-gray;
  &:hover,
  &:active,
  &:focus {
    background-color: $boxitem-title-color-gray;
  }
}

.resource-collapse-header-button-green:not(:disabled):not(.disabled) {
  background-color: $boxitem-title-color-green;
  &:hover,
  &:active,
  &:focus {
    background-color: $boxitem-title-color-green;
  }
}

.chart-overlay {
  h3 {
    position: absolute;
    margin: auto;
    margin-top: 4em;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
