/* ------------------------------------- [ Follow on page ] ------------------------------------- */

.boxitem-content.boxitem-form label {
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media (hover:hover) {

  .boxitem-content.boxitem-form label:hover {
    background-color: rgba(173, 216, 230, 0.4);
  }
}

.boxitem-form-text {
  padding-top: 0.5em;
  padding-bottom: 1em;
}

.boxitem-content.boxitem-form label.checkbox-item {
  padding-bottom: 0.5em;
  margin-bottom: 0;
  
}
.boxitem-content.boxitem-form div.checkbox-item {
  &:nth-child(2n+0) {
    @media (hover: none) {
      background-color: rgba(173, 216, 230, 0.2);
    }
  }
}
.boxitem-content.boxitem-form label {
  margin-left: 0em;
  padding-left: 1em;
  padding-right: 1em;
}

@mixin input-position() {
  position: relative;
  margin-left: -1em;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
}

.checkbox-item input[type="checkbox"] {
  @include input-position();
}

.radio-item {
  input[type="radio"] {
    @include input-position();
  }
  input[type="text"] {
    margin-top: 1em;
  }
}

/* remove excess padding for stacked form-groups */

.form-group + .form-group .boxitem-title {
  margin-top: 0px;
}

.instruction-text {
  text-align: center;
  font-size: 1.4em;
}
