/* Wellbeing survey formatting */
.radioQuestion {
    font-size:1.4rem;
}

.question {
    width:100%;
}

.smiley-question {
    width:19%;
    display: inline-block;
    padding-top:50%;
    padding-bottom: 50%;
}

.form-check {
    
width: 19%;
    display: inline-block !important;
} 

.question:hover { 
    color: #464;
}

.form-group h3 { 
    margin-bottom: 30px 
}

.questionWrapper { 
    border:3px solid #005630; border-radius: 50px; padding:10px;
    box-shadow: 0px 0px 10px #BBB;
  }
.white { background: #FFF; } /* This should be shifted out of here soon */


.likert {
    margin: 40px 0 40px 5%;
    width: 90%;
}

.likert .rc-slider-mark-text {
    width: 15%;
}

@media (min-width: 768px) {
    .radioQuestion { font-size: 1rem; }
 }

.questionWrapper h3 { text-align:center; font-weight:800; }

h3 .badge {
    margin-right:1rem;
}

.form-group h3 { font-size:1.50rem;  }

@media (min-width: 768px) {
    .form-group h3 { font-size: 1.2rem; }
 }
