/* SMILEY */

@mixin smiley-base($n) {
  background-image: url("../images/rating/#{$n+1}.png");
  background-repeat: no-repeat;
}

#smiley .form-check {
  text-align: center;
  padding-left: 0px;
  @media screen and (min-width: 768px) {
    margin-left: 2px;
    margin-right: 2px;
    & label.smiley-question {
      background-size: 30%;
    }
  }
  border-radius: 0.5em;
  &.active,
  &:hover {
    background-color: #d9dadc;
  }
}

#smiley .form-check label {
  cursor: pointer;
  min-height: 60px;
  width: 100%;
  background-position: center;
  transition: all 0.3s ease;
  background-size: 70%;
  margin-bottom: 0em;
  @media screen and (min-width: 768px) {
    margin-top: -2em;
  }
}

.questionWrapper h3 {
  padding-bottom: 1em;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 0.8em;
  span {
    font-weight: bold;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.2em;
  }
}

#smiley input {
  opacity: 0.01;
}

.smiley-label {
  font-weight: bold;
  margin-top: -2.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
}

@for $n from 1 through 5 {
  #smiley .form-check:nth-child(#{$n}) label {
    @include smiley-base($n);
  }
}
